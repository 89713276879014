import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-193d9c0d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portfolio flex flex-col h-full" }
const _hoisted_2 = { class: "bg-light flex-grow" }
const _hoisted_3 = { class: "container py-4 flex flex-col justify-start" }
const _hoisted_4 = { class: "flex flex-row justify-center align-middle pt-4 pb-8" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "py-8 flex flex-row flex-wrap justify-center" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "fixed w-screen h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center"
}
const _hoisted_13 = { class: "container" }
const _hoisted_14 = { class: "p-8 rounded-lg bg-white shadow-lg flex flex-col justify-center items-center" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_breastfeeding_banner = _resolveComponent("app-breastfeeding-banner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_breastfeeding_banner),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["filter-btn mx-4 py-2 px-10 rounded-full uppercase border-2 border-secondary bg-primary text-white font-bold text-sm cursor-pointer whitespace-nowrap", { active: _ctx.prodFilter === 'all' }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prodFilter = 'all')),
            innerHTML: _ctx.t('portfolio.allProducts')
          }, null, 10, _hoisted_5),
          _createElementVNode("div", {
            class: _normalizeClass(["filter-btn mx-4 py-2 px-10 rounded-full uppercase border-2 border-secondary bg-primary text-white font-bold text-sm cursor-pointer", { active: _ctx.prodFilter === 'special' }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.prodFilter = 'special')),
            innerHTML: _ctx.t('portfolio.special')
          }, null, 10, _hoisted_6),
          _createElementVNode("div", {
            class: _normalizeClass(["filter-btn mx-4 py-2 px-10 rounded-full uppercase border-2 border-secondary bg-primary text-white font-bold text-sm cursor-pointer", { active: _ctx.prodFilter === 'regular' }]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.prodFilter = 'regular')),
            innerHTML: _ctx.t('portfolio.regular')
          }, null, 10, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProducts, (product, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "product",
              key: `product-${idx}`
            }, [
              _createElementVNode("div", {
                class: "w-32 h-32 mx-4 my-4 p-4 rounded-full cursor-pointer text-center flex flex-col justify-end shadow hover:shadow-md",
                style: _normalizeStyle({ backgroundColor: product.color }),
                onClick: ($event: any) => (_ctx.gotoProduct(product.slug))
              }, [
                _createElementVNode("img", {
                  class: "w-8/12 mx-auto",
                  src: product.image.packshotThumb,
                  alt: product.text.name
                }, null, 8, _hoisted_10),
                _createElementVNode("div", {
                  class: "product-name text-white text-sm leading-3 pt-1",
                  innerHTML: product.text.name
                }, null, 8, _hoisted_11)
              ], 12, _hoisted_9)
            ]))
          }), 128))
        ])
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
      (_ctx.showPortfolioAgreementPopup)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", {
                  class: "mb-8 text-lg leading-5 text-primary text-justify",
                  innerHTML: _ctx.t('portfolio.notice.textA')
                }, null, 8, _hoisted_15),
                _createElementVNode("div", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.agreePortfolio && _ctx.agreePortfolio(...args))),
                  class: "mx-auto py-2 px-10 rounded-full uppercase border-2 border-secondary bg-primary text-white font-bold text-sm cursor-pointer whitespace-nowrap",
                  innerHTML: _ctx.t('portfolio.notice.buttonA')
                }, null, 8, _hoisted_16)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]))
  ]))
}